import FormVeiculoTroca from 'src/components/FormVeiculoTroca/FormVeiculoTroca.vue'

export default {
  name: 'QueroVende2',
  components: { FormVeiculoTroca },
  meta () {
    return {
      title: 'Venda seu carro sem sair de casa - NetCarros',
      description: { name: 'description', content: 'Precisa vender seu carro? Receba uma oferta no seu carro em 5 minutos. Nós da NetCarros valorizamos seu carro usado. Receba o dinheiro em 24h. Acesse já!' },
      property: { 'name': 'og:image', content: 'https://www.netcarros.com.br/seo/netcarros-vender.jpg' },
      link: { material: { rel: 'canonical', href: 'https://www.netcarros.com.br' + this.$route.fullPath } },
      meta: [
        { 'property': 'og:title', 'content': 'Venda seu carro sem sair de casa - NetCarros' },
        { 'name': 'description', 'content': 'Precisa vender seu carro? Receba uma oferta no seu carro em 5 minutos. Nós da NetCarros valorizamos seu carro usado. Receba o dinheiro em 24h. Acesse já!' },
        { 'property': 'revisit-after', 'content': '5 days' },
        { 'property': 'og:image', 'content': 'https://www.netcarros.com.br/seo/netcarros-vender.jpg' },
        { 'property': 'og:image:secure_url', 'content': 'https://www.netcarros.com.br/seo/netcarros-vender.jpg' },
        { 'property': 'og:url', 'content': 'https://www.netcarros.com.br' + this.$route.fullPath },
        { 'property': 'og:site_name', 'content': 'NetCarros' },
        { 'property': 'fb:admins', 'content': '', 'vmid': 'fb:admins' },
        { 'property': 'og:locale', 'content': 'pt_BR' }
      ]
    }
  },
  data () {
    return {
      mounted: false
    }
  },
  mounted () {
    this.mounted = true
    this.linkpage = 'https://api.whatsapp.com/send?phone=551151980247&text=Olá%20gostaria%20de%20vender%20meu%20veículo,%20como%20eu%20faço?'
    document.querySelector('.botoes a').href = this.linkpage
  }
}
